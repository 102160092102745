import { render, staticRenderFns } from "./release-upload.vue?vue&type=template&id=1a30ffda&scoped=true&"
import script from "./release-upload.vue?vue&type=script&lang=js&"
export * from "./release-upload.vue?vue&type=script&lang=js&"
import style0 from "./release-upload.vue?vue&type=style&index=0&id=1a30ffda&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a30ffda",
  null
  
)

export default component.exports